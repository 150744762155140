<template>
  <v-card class="px-5 py-4" flat>
    <v-card-title class="font-weight-bold px-0 pt-0">Ảnh và video</v-card-title>

    <v-checkbox
      v-model="productOption.is_inherit_gallery"
      class="mt-0"
      label="Sử dụng cùng bộ hình ảnh với sản phẩm"
    ></v-checkbox>

    <v-row v-if="!productOption.is_inherit_gallery">
      <v-col class="py-0 d-flex">
        <div>
          <div class="font-weight-bold mb-2">Video</div>

          <v-hover v-slot:default="{ hover }">
            <div class="video-wrapper" @click="handleOpenModalInsertVideo">
              <div class="gallery-add rounded">
                <v-icon
                  v-if="
                    !productOption.social_media ||
                      !productOption.social_media.url
                  "
                  class="grey--text text--lighten-1"
                  large
                  >mdi-plus
                </v-icon>

                <v-img
                  v-if="
                    productOption.social_media && productOption.social_media.url
                  "
                  :src="productOption.social_media.thumbnail"
                  alt="youtube-thumbnail"
                  class="h-full"
                  contain
                  ><v-fade-transition>
                    <v-row
                      v-if="hover && productOption.social_media.url"
                      align="center"
                      class="fill-height grey darken-4 ma-0 rounded"
                      justify="center"
                      style="opacity: 0.65; position: absolute;width: 100%;"
                    >
                      <v-btn
                        v-if="productOption.social_media.url"
                        color="white"
                        icon
                        @click.stop="showVideoPreview = true"
                      >
                        <v-icon>mdi-eye-outline</v-icon>
                      </v-btn>

                      <v-btn color="white" icon @click="removeVideo">
                        <v-icon>mdi-delete-outline</v-icon>
                      </v-btn>
                    </v-row>
                  </v-fade-transition>
                </v-img>

                <v-dialog v-model="showVideoPreview" width="500px">
                  <video
                    v-if="productOption.social_media"
                    ref="videoPlayer"
                    :key="productOption.social_media.url"
                    class="black"
                    style="max-height: 500px;"
                    controls
                  >
                    <source
                      :src="
                        showVideoPreview ? productOption.social_media.url : ''
                      "
                      type="video/mp4"
                    />
                    Trình duyệt của bạn không hỗ trợ video.
                  </video>
                </v-dialog>
              </div>
            </div>
          </v-hover>
        </div>

        <div class="ml-4 overflow-auto">
          <div class="relative">
            <div class="font-weight-bold mb-2 d-flex">
              <span>Bộ ảnh</span>

              <image-processing-guide></image-processing-guide>
            </div>

            <button-expand-card-gallery
              class="button-expand"
            ></button-expand-card-gallery>
          </div>

          <div class="gallery pb-3">
            <t-upload-image
              v-slot="{
                on,
                isCompressing
              }"
              :max-file-size="700 * 1024"
              :min-size="{ width: 2000, height: 1125 }"
              :short-pixel-api-keys="apiKeys"
              :target-size="{ width: 2000, height: 2000 }"
              allow-compare-image
              allow-compress
              allow-file-dimension-validation
              allow-file-size
              allow-file-type-validation
              allow-resize-file
              required
              @change="onChangeImage"
            >
              <fieldset
                :disabled="isCompressing"
                class="gallery-add rounded"
                v-on="on"
              >
                <v-btn :loading="isCompressing" icon>
                  <v-icon class="grey--text text--lighten-1" large
                    >mdi-plus
                  </v-icon>
                </v-btn>
              </fieldset>
            </t-upload-image>

            <draggable
              v-model="productOption.images"
              v-horizontal-scroll
              class="ml-2 preview-gallery"
              v-bind="dragOptions"
            >
              <v-hover
                v-for="(item, index) in productOption.images"
                :key="index"
                v-slot:default="{ hover }"
              >
                <div class="gallery-item">
                  <v-img
                    :aspect-ratio="1"
                    :src="
                      item && item.slice(0, 5) !== 'blob:' ? item : undefined
                    "
                    class="grey lighten-3 rounded tp-boder-thin mr-2 cursor-move"
                    height="120px"
                    width="120px"
                  >
                    <v-fade-transition>
                      <v-row
                        v-if="hover"
                        align="center"
                        class="fill-height grey darken-4 ma-0"
                        justify="center"
                        style="opacity: 0.65"
                      >
                        <v-btn color="white" icon @click="previewImage(item)">
                          <v-icon>mdi-eye-outline</v-icon>
                        </v-btn>

                        <v-btn color="white" icon @click="removeImage(item)">
                          <v-icon>mdi-delete-outline</v-icon>
                        </v-btn>
                      </v-row>
                    </v-fade-transition>
                    <template v-slot:placeholder>
                      <v-row
                        align="center"
                        class="fill-height ma-0"
                        justify="center"
                      >
                        <v-progress-circular
                          color="grey lighten-5"
                          indeterminate
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </div>
              </v-hover>
            </draggable>
          </div>
        </div>
      </v-col>
    </v-row>
    <modal-preview-sku-image :url="selectedImage"></modal-preview-sku-image>

    <modal-insert-sku-video
      @onInserted="handleInsertedVideo"
    ></modal-insert-sku-video>
  </v-card>
</template>
<script>
import { showModalAlertError } from "@/core/composables";
import TUploadImage from "@vt7/upload-image/lib/t-upload-image.vue";
import { toFormData } from "@vt7/utils";
import draggable from "vuedraggable";
import ModalInsertSkuVideo from "../modals/ModalInsertSkuVideo";
import ModalPreviewSkuImage from "../modals/ModalPreviewSkuImage";
import ButtonExpandCardGallery from "./ButtonExpandCardGallery.vue";
import ImageProcessingGuide from "@/core/components/general/ImageProcessingGuide/index.vue";

export default {
  data() {
    return {
      apiKeys: process.env.VUE_APP_COMPRESS_API_KEYS.split(","),
      file: null,
      selectedImage: "",
      showVideoPreview: false
    };
  },

  components: {
    draggable,
    ButtonExpandCardGallery,
    ModalInsertSkuVideo,
    ModalPreviewSkuImage,
    TUploadImage,
    ImageProcessingGuide
  },

  computed: {
    categories() {
      return this.$store.getters["PRODUCT_TYPE/categories"];
    },

    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    },

    product() {
      return this.$store.getters["PRODUCT/product"];
    },

    productOption() {
      return this.$store.getters["PRODUCT_OPTION/productOption"];
    },

    productModelSiteConfigs() {
      return this.$store.getters["PRODUCT/productModelSiteConfigs"];
    },

    productStatusRequest() {
      return this.$store.getters["PRODUCT/statusRequest"];
    },

    selectedSites() {
      return this.productModelSiteConfigs
        .filter(item => item.available)
        .map(item => item.site);
    },

    tempThumbnailExpiredTime: {
      get() {
        return this.getTime(
          this.productOption.temporary_thumbnail.expired_time
        );
      },
      set(val) {
        this.productOption.temporary_thumbnail.expired_time = this.setTime(
          this.productOption.temporary_thumbnail.expired_time,
          val
        ).toISOString();
      }
    },

    uploadedImage() {
      return this.$store.getters["PRODUCT/uploadedThumbnail"];
    }
  },

  methods: {
    clearTempThumbnail() {
      this.productOption.temporary_thumbnail.expired_time = new Date().toISOString();
      this.productOption.temporary_thumbnail.path = null;
    },

    getSitesByCategoryId(categoryId) {
      return this.categories.find(item => item.id === categoryId).sites;
    },

    getTime(date) {
      if (!date || date === "") return "00:00";

      const dateTime = new Date(date),
        hours = dateTime
          .getHours()
          .toString()
          .padStart(2, "0"),
        mins = dateTime
          .getMinutes()
          .toString()
          .padStart(2, "0");

      return `${hours}:${mins}`;
    },

    async handleInsertedVideo(url, thumbnailUrl) {
      if (!this.productOption.social_media) {
        this.productOption.social_media = {};
      }
      this.productOption.social_media.url = url || "";
      this.productOption.social_media.thumbnail = thumbnailUrl || "";
    },

    handleOpenModalInsertVideo() {
      this.$modal.show({ name: "model-insert-video-sku" });
    },

    previewImage(url) {
      this.selectedImage = url;
      this.$modal.show({ name: "modal-preview-sku-image" });
    },

    removeImage(link) {
      this.productOption.images = this.productOption.images.filter(item => {
        return item !== link;
      });
    },

    removeVideo(event) {
      event.stopPropagation();

      this.productOption.social_media.url = "";
      this.productOption.social_media.thumbnail = "";
    },

    async onChangeImage(file) {
      await this.onUploadImage(file);
    },

    async onUploadImage(file) {
      await this.uploadImage(
        toFormData({
          file: file
        })
      );

      // Set uploaded image
      if (this.productStatusRequest.value === "success-uploadThumbnail") {
        this.productOption.images = this.productOption.images.concat(
          this.uploadedImage
        );
      } else if (this.productStatusRequest.value === "error-uploadThumbnail") {
        showModalAlertError(this, {
          title: "Lỗi tải lên hình ảnh",
          message: this.productStatusRequest.message
        });

        return;
      }
    },

    async uploadImage(formData) {
      if (!formData) return;

      const payload = {
        formData: formData,
        name: this.productOption.name,
        sites: this.selectedSites.length
          ? this.selectedSites
          : this.getSitesByCategoryId(this.product.category_id)
      };

      await this.$store.dispatch("PRODUCT/uploadThumbnail", payload);
    },

    setTime(date, time) {
      let dateTime = new Date(date);
      const [hours, mins] = time.split(":");

      dateTime.setHours(parseInt(hours));
      dateTime.setMinutes(parseInt(mins));

      return dateTime;
    }
  },

  watch: {
    showVideoPreview(newVal) {
      if (!newVal) {
        const video = this.$refs.videoPlayer;
        if (video) {
          video.pause();
          video.currentTime = 0;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.button-expand {
  position: absolute;
  right: 0;
  top: -8px;
}

.cursor-move {
  cursor: move;
}

.ghost {
  opacity: 0.5;
}

.gallery {
  display: flex;
  width: 100%;

  &-add {
    align-items: center;
    border: 2px dashed #e5e5e5;
    cursor: pointer;
    display: flex;
    justify-content: center;
    height: 120px !important;
    transition: all 0.4s ease;
    width: 120px !important;

    &:hover {
      background-color: #f9f9f9;
      border-color: #e0e0e0;
    }
  }

  &-item {
    display: flex;

    .v-image {
      border: 1px solid #e5e5e5;
    }
  }
}

.preview-gallery {
  display: flex;
  flex: 1;
  overflow: auto;
}

.video-wrapper {
  position: relative;
}

.video-wrapper::before {
  content: "";
  position: absolute;
  width: 2px;
  height: 60%;
  background-color: #e5e5e5;
  right: -9px;
  top: 50%;
  transform: translateY(-50%);
}
</style>
