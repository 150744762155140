<template>
  <tp-modal name="model-insert-video-sku" width="640px">
    <v-card>
      <v-card-title>
        <div class="font-weight-bold">
          Tải video lên
        </div>

        <v-spacer></v-spacer>

        <v-btn color="primary" dark depressed @click="handleInsertVideo">
          Lưu
        </v-btn>

        <v-btn icon @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-container class="grey lighten-3 pa-5" fluid>
        <v-card class="pa-5" flat>
          <v-btn
            color="primary"
            depressed
            outlined
            :loading="loading"
            @click="$refs.videoInput.click()"
          >
            Upload
            <v-icon right>
              mdi-cloud-upload
            </v-icon>
          </v-btn>

          <div v-if="internalUrl" class="mt-4">
            <video :key="internalUrl" style="max-height: 300px;" controls>
              <source :src="internalUrl" type="video/mp4" />
              Trình duyệt của bạn không hỗ trợ video.
            </video>
          </div>

          <div
            class="red--text text--accent-2 my-1 text-body-2"
            v-if="errorMessage"
          >
            {{ errorMessage }}
          </div>

          <input
            type="file"
            ref="videoInput"
            accept="video/*"
            style="display: none"
            @change="handleFileUpload"
          />
        </v-card>
      </v-container>
    </v-card>
  </tp-modal>
</template>

<script>
import MediaService from "@/core/service/media.service";

export default {
  data() {
    return {
      internalUrl: "",
      thumbnailUrl: "",
      errorMessage: "",
      maxSize: 50 * 1024 * 1024, // 50MB
      loading: false
    };
  },

  methods: {
    clearVideo() {
      this.internalUrl = "";
      this.errorMessage = "";
    },

    closeModal() {
      this.$modal.hide({ name: "model-insert-video-sku" });
      this.clearVideo();
    },

    handleInsertVideo() {
      this.$emit("onInserted", this.internalUrl, this.thumbnailUrl);
      this.closeModal();
    },

    handleFileUpload(event) {
      const file = event.target.files[0];
      if (!file) return;

      const isVideoOversized = file.size > this.maxSize;

      this.clearVideo();

      if (isVideoOversized) {
        this.errorMessage = "Kích thước video không được vượt quá 50MB.";
        return;
      } else {
        this.loading = true;

        const formData = new FormData();
        formData.append("file", file);
        event.target.value = "";

        MediaService.uploadVideo(formData)
          .then(response => {
            this.internalUrl = response.data.video_url;
            this.thumbnailUrl = response.data.thumbnail_url;
          })
          .catch(error => {
            this.errorMessage = "Có lỗi xảy ra khi tải video lên.";
            console.error(error);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }
  }
};
</script>
